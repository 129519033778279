import ApplicationSetting from "./application.constants";

export const ToolStoreUrl = `${ApplicationSetting.apiUrl}/api`;

export const RootApiUrl = `${ApplicationSetting.apiUrl}`;

export const ApiConstants = {
  equipment: {
    root: `${ToolStoreUrl}/equipment`,
    getEquipmentByGlobalId(globalId: string) { return `${this.root}/${globalId}` },
  },
  checking: {
    root: `${ToolStoreUrl}/equipment-checking`,
    checkInOutEquipment() { return this.root },
  },
  users: {
    root: `${ToolStoreUrl}/users`,
    getToken() { return `${this.root}/token` }
  },
  deviceSetups:{
    root: `${ToolStoreUrl}/tool-store-device-setups`,
    registerToolStoreRoom() { return `${this.root}` },
    getRoomSetting() { return `${this.root}/get-room-setting` },
  },
  settings:{
    root: `${ToolStoreUrl}/settings`,
    getcompaniesWithPrivilege() { return `${this.root}/companies-with-privilege` },
    getCompanyPrivileges() { return `${this.root}/get-companies` },
    getLocations(companyId: number) { return `${this.root}/${companyId}/locations` },
    getAreas(companyId: number, locationId: number) { return `${this.root}/${companyId}/locations/${locationId}/areas` },
    checkLatestVersion(version: string) { return `${this.root}/latest-version/${version}`; }
  },
  deviceLog: {
    root: `${ToolStoreUrl}/device-logs`,
    createLog() { return `${this.root}` }
  }
};
