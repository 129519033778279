import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { ApplicationVersion, ServerName } from "../../constants/application.constants"
import { roomInfoSelector } from "../../states/app/app.slice"
import { authenInfoSelector } from "../../states/app/check-in-out-state.slice"
import { InfoButton } from "../Button/IconButton"
import { OnixCheckoutIcon } from "../Logo/Logo"
import { Popover } from "../Popover/Popover"
import "./index.scss"
import { css } from "../../utils/css.utils"
import { useRef } from "react"
import { useForceRender } from "../../hooks/useForceRender"

export const MainNav = () => {
  const [t] = useTranslation()
  const roomInfo = useSelector(roomInfoSelector)
  const authenInfo = useSelector(authenInfoSelector)
  const serverName = ServerName

  // This state will just keep current value, so it can be fade out.
  const currentUserInfo = useRef(authenInfo?.name ?? "")

  const forceRender = useForceRender()

  if (authenInfo?.name) {
    currentUserInfo.current = authenInfo?.name
  }
  else if (!authenInfo?.name) {
    // We don't actually need this, since the CSS transition will set opacity to 0 anyway.
    // I reset it to empty so future people won't get confused :)
    // This means, we don't need the useForceRender hook.
    setTimeout(() => {
      currentUserInfo.current = ""
      forceRender()
    }, 300)
  }

  return (
    <div className="otg-main-nav">
      <div className="otg-logo"><OnixCheckoutIcon /></div>
      <div className={css("otg-info", authenInfo && "has-info")}>
        <div className="user-info">{currentUserInfo.current}</div>

        <div className="tooltip-wrapper">
          <Popover
            className="info-tooltip"
            content={(
              <div className="info-wrapper">
                <div className="col strong">{t("Home.Server")}:</div>
                <div className="col">{serverName}</div>

                <div className="col strong">{t("AppInspect.ToolGateGateID")}:</div>
                <div className="col">{roomInfo.roomId}</div>

                <div className="col strong">{t("CommonResource.Version")}:</div>
                <div className="col">{ApplicationVersion}</div>
              </div>
            )}
          >
            <InfoButton className="has-authen-info" size="40px" />
          </Popover>
        </div>
      </div>
    </div>
  )
}
