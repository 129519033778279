import { Icon as FluentIcon, IIconProps, IStyle } from "@fluentui/react"
import { css } from "../../utils/css.utils"

export interface IconProps extends IIconProps {
  iconName: string,
  className?: string,
  size?: number | string,
}

export const Icon = (props: IconProps) => {
  const styles: IStyle = {
    fontSize: props.size,
    ...props.styles,
  }

  return (
    <FluentIcon {...props} className={css("otg-icon", props.className)} styles={{ root: styles }} />
  )
}

export type ToolGateIconProps = Omit<IconProps, "iconName">

const createIconComponent = (iconName: string): (props: ToolGateIconProps) => JSX.Element => {
  const iconComponent = (props: ToolGateIconProps) => <Icon {...props} iconName={iconName} />

  iconComponent.displayName = `${iconName}Icon`

  return iconComponent
}

export const InfoIcon = createIconComponent("Info")
export const CheckIcon = createIconComponent("CheckMark")
export const CancelIcon = createIconComponent("Cancel")
