import ApplicationSetting from "../constants/application.constants";
import { i18n, parseLanguageIdToLanguageShort } from "../constants/languages.constants";
import { SettingKeyConstants } from "../constants/setting-keys.constants";
import { SignalRConstants } from "../constants/signalr.constants";
import { AppSettings } from "../models/app-settings.model";
import { DeviceSetup } from "../models/device-setup.model";
import { DoorState } from "../models/door-state.model";
import { RoomInfo } from "../models/redux.model";
import { LogRecord } from "../utils/debug.utils";
import { now } from "../utils/common.utils";
import { log } from "../utils/log.utils";

const getLocalStorageKey = (key: string): string => `${ApplicationSetting.appName}.${ApplicationSetting.environment}.${key}`;

const getItemString = (key: string): string | null => localStorage.getItem(getLocalStorageKey(key));

const getItem = <V>(key: string): V | null => {
  const item = getItemString(key);
  if (item === null) {
    return item;
  }

  return JSON.parse(item);
};
type PrimitiveType = number | string | boolean;
type ItemOrDefault<V> = V extends object | PrimitiveType ? V : (() => V);

const getItemOrDefault = <V>(key: string, defaultValue: ItemOrDefault<V>): V =>
  getItem<V>(key) ?? (typeof defaultValue !== "function" ? defaultValue : defaultValue());

const getSettings = (): AppSettings => {
  const defaultSettings = SignalRConstants.Default;
  return {
    confirmTimeoutInSecond: getItemOrDefault(SettingKeyConstants.ConfirmTimeoutInSecond, defaultSettings.ConfirmTimeoutInSecond),
    idleTimeoutInSecond: getItemOrDefault(SettingKeyConstants.IdleTimeoutInSecond, defaultSettings.IdleTimeoutInSecond),
    languageId: getItemOrDefault(SettingKeyConstants.LanguageId, defaultSettings.LanguageId),
    signalRUrl: getItemOrDefault(SettingKeyConstants.SignalRUrl, defaultSettings.SignalRUrl),
  }
}
const setRoomSettings=(device: DeviceSetup)=>{
  var language = parseLanguageIdToLanguageShort(device.languageId);
  i18n.changeLanguage(language);
  localStorage.setItem(getLocalStorageKey(SettingKeyConstants.LanguageId), JSON.stringify(device.languageId));
  localStorage.setItem(getLocalStorageKey(SettingKeyConstants.ConfirmTimeoutInSecond), JSON.stringify(device.confirmTimeoutInSecond));
  localStorage.setItem(getLocalStorageKey(SettingKeyConstants.IdleTimeoutInSecond), JSON.stringify(device.idleTimeoutInSecond));
  localStorage.setItem(getLocalStorageKey(SettingKeyConstants.LogServerSideEnabled), JSON.stringify(device.logEnabled));
}

const setItem = <T extends any>(key: string, value: T) => localStorage.setItem(getLocalStorageKey(key), JSON.stringify(value));

const saveRoomInfo = (roomInfo: RoomInfo) => setItem(SettingKeyConstants.RoomInfo, roomInfo);

const shouldLog = () => getItemOrDefault(SettingKeyConstants.ShowDebugLog, false);
const shouldLogServerSide = () => getItemOrDefault<boolean>(SettingKeyConstants.LogServerSideEnabled, true); 

const getDoorState = (): DoorState => getItemOrDefault(SettingKeyConstants.DoorState, SignalRConstants.DefaultDoorState);
const setDoorState = (doorState: DoorState) => setItem(SettingKeyConstants.DoorState, doorState);

const shouldLogClientSide = (): boolean => {
  return getItemOrDefault(SettingKeyConstants.LogClientSideEnabled, false)
}

const setLogRecord = (value: boolean = false) => {
  if (value) {
    log(`LogRecord: started logging.`);
    setItem(SettingKeyConstants.LogClientSideEnabled, true)
    return
  }

  log(`LogRecord: stopped logging.`);
  setItem(SettingKeyConstants.LogClientSideEnabled, false)
  return
}

const getLogRecord = (): LogRecord[] => {
  return getItemOrDefault(SettingKeyConstants.LogClientSideRecords, [])
}

const addLogRecord = (logRecord: Omit<LogRecord, "date">) => {
  if (!shouldLogClientSide()) {
    return
  }

  var record = {
    ...logRecord,
    date: now()
  }

  const records = getLogRecord()

  records.push(record)

  setItem(SettingKeyConstants.LogClientSideRecords, records)
}

const clearLogRecord = () => {
  setItem(SettingKeyConstants.LogClientSideRecords, [])
  log(`LogRecord: log cleared.`)
}


export const LocalStorageService = {
  getLocalStorageKey,
  getItemString,
  getItem,
  getItemOrDefault,
  getSettings,
  setItem,
  saveRoomInfo,
  shouldLog,
  shouldLogServerSide,
  setRoomSettings,
  getDoorState,
  setDoorState,
  shouldLogClientSide,
  setLogRecord,
  getLogRecord,
  addLogRecord,
  clearLogRecord,
}
